.button {
  user-select: none;
  cursor: pointer;
}

.button:hover {
  opacity: 0.8;
}

.button:active {
  opacity: 0.7;
}

.button.disabled {
  opacity: 1 !important;
}

.orion_button,
.orion_button:active,
.orion_button:hover {
  user-select: none;
  box-shadow: none;
  border-radius: 5px;
  background-color: #eee;
  text-transform: none;
}

.orion_button:hover {
  opacity: 0.8;
  box-shadow: none;
  transform: none;
}

.orion_button:active {
  background-color: #eee;
  opacity: 0.7;
  box-shadow: none;
  transform: none;
}

.orion_button.orion_disabled {
  opacity: 1 !important;
}

.orion_danger,
.orion_danger:active,
.orion_danger:hover {
  background-color: #f44336;
}

.orion_primary,
.orion_primary:active,
.orion_primary:hover {
  background-color: #0f2ff1;
}
