@import url(https://fonts.googleapis.com/css?family=Alegreya|Roboto&display=swap);
body {
  margin: 0;
  padding: 0;
  background: #fff;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

body .p-button {
  margin: 0;
  color: #fff;
  background-color: #1976D2;
  border: 1px solid #1976D2;
  font-size: 14px;
  -webkit-transition: background-color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, box-shadow 0.2s;
  border-radius: 3px;
}

body .p-button.p-button-success,
body .p-buttonset.p-button-success>.p-button {
  color: #212121;
  background-color: #8BC34A;
  border: 1px solid #8BC34A;
}

body .p-button.p-button-danger,
body .p-buttonset.p-button-danger>.p-button {
  color: #ffffff;
  background-color: #D32F2F;
  border: 1px solid #D32F2F;
}

.container-fluid,
.container {
  margin-right: auto;
  margin-left: auto;
}

.container-fluid {
  padding-right: 2rem;
  padding-left: 2rem;
}

.row {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.row.reverse {
  flex-direction: row-reverse;
}

.col.reverse {
  flex-direction: column-reverse;
}

.col-xs,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-offset-1,
.col-xs-offset-2,
.col-xs-offset-3,
.col-xs-offset-4,
.col-xs-offset-5,
.col-xs-offset-6,
.col-xs-offset-7,
.col-xs-offset-8,
.col-xs-offset-9,
.col-xs-offset-10,
.col-xs-offset-11,
.col-xs-offset-12 {
  box-sizing: border-box;
  flex: 0 0 auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.col-xs {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.col-xs-1 {
  flex-basis: 8.333%;
  max-width: 8.333%;
}

.col-xs-2 {
  flex-basis: 16.667%;
  max-width: 16.667%;
}

.col-xs-3 {
  flex-basis: 25%;
  max-width: 25%;
}

.col-xs-4 {
  flex-basis: 33.333%;
  max-width: 33.333%;
}

.col-xs-5 {
  flex-basis: 41.667%;
  max-width: 41.667%;
}

.col-xs-6 {
  flex-basis: 50%;
  max-width: 50%;
}

.col-xs-7 {
  flex-basis: 58.333%;
  max-width: 58.333%;
}

.col-xs-8 {
  flex-basis: 66.667%;
  max-width: 66.667%;
}

.col-xs-9 {
  flex-basis: 75%;
  max-width: 75%;
}

.col-xs-10 {
  flex-basis: 83.333%;
  max-width: 83.333%;
}

.col-xs-11 {
  flex-basis: 91.667%;
  max-width: 91.667%;
}

.col-xs-12 {
  flex-basis: 100%;
  max-width: 100%;
}

.col-xs-offset-1 {
  margin-left: 8.333%;
}

.col-xs-offset-2 {
  margin-left: 16.667%;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-offset-4 {
  margin-left: 33.333%;
}

.col-xs-offset-5 {
  margin-left: 41.667%;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-offset-7 {
  margin-left: 58.333%;
}

.col-xs-offset-8 {
  margin-left: 66.667%;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-offset-10 {
  margin-left: 83.333%;
}

.col-xs-offset-11 {
  margin-left: 91.667%;
}

.start-xs {
  justify-content: flex-start;
  text-align: start;
}

.center-xs {
  justify-content: center;
  text-align: center;
}

.end-xs {
  justify-content: flex-end;
  text-align: end;
}

.top-xs {
  align-items: flex-start;
}

.middle-xs {
  align-items: center;
}

.bottom-xs {
  align-items: flex-end;
}

.around-xs {
  justify-content: space-around;
}

.between-xs {
  justify-content: space-between;
}

.first-xs {
  order: -1;
}

.last-xs {
  order: 1;
}

@media only screen and (min-width: 48em) {
  .container {
    width: 49rem;
  }

  .col-sm,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-offset-1,
  .col-sm-offset-2,
  .col-sm-offset-3,
  .col-sm-offset-4,
  .col-sm-offset-5,
  .col-sm-offset-6,
  .col-sm-offset-7,
  .col-sm-offset-8,
  .col-sm-offset-9,
  .col-sm-offset-10,
  .col-sm-offset-11,
  .col-sm-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .col-sm {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-sm-1 {
    flex-basis: 8.333%;
    max-width: 8.333%;
  }

  .col-sm-2 {
    flex-basis: 16.667%;
    max-width: 16.667%;
  }

  .col-sm-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex-basis: 33.333%;
    max-width: 33.333%;
  }

  .col-sm-5 {
    flex-basis: 41.667%;
    max-width: 41.667%;
  }

  .col-sm-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex-basis: 58.333%;
    max-width: 58.333%;
  }

  .col-sm-8 {
    flex-basis: 66.667%;
    max-width: 66.667%;
  }

  .col-sm-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex-basis: 83.333%;
    max-width: 83.333%;
  }

  .col-sm-11 {
    flex-basis: 91.667%;
    max-width: 91.667%;
  }

  .col-sm-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-sm-offset-1 {
    margin-left: 8.333%;
  }

  .col-sm-offset-2 {
    margin-left: 16.667%;
  }

  .col-sm-offset-3 {
    margin-left: 25%;
  }

  .col-sm-offset-4 {
    margin-left: 33.333%;
  }

  .col-sm-offset-5 {
    margin-left: 41.667%;
  }

  .col-sm-offset-6 {
    margin-left: 50%;
  }

  .col-sm-offset-7 {
    margin-left: 58.333%;
  }

  .col-sm-offset-8 {
    margin-left: 66.667%;
  }

  .col-sm-offset-9 {
    margin-left: 75%;
  }

  .col-sm-offset-10 {
    margin-left: 83.333%;
  }

  .col-sm-offset-11 {
    margin-left: 91.667%;
  }

  .start-sm {
    justify-content: flex-start;
    text-align: start;
  }

  .center-sm {
    justify-content: center;
    text-align: center;
  }

  .end-sm {
    justify-content: flex-end;
    text-align: end;
  }

  .top-sm {
    align-items: flex-start;
  }

  .middle-sm {
    align-items: center;
  }

  .bottom-sm {
    align-items: flex-end;
  }

  .around-sm {
    justify-content: space-around;
  }

  .between-sm {
    justify-content: space-between;
  }

  .first-sm {
    order: -1;
  }

  .last-sm {
    order: 1;
  }
}

@media only screen and (min-width: 64em) {
  .container {
    width: 65rem;
  }

  .col-md,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-offset-1,
  .col-md-offset-2,
  .col-md-offset-3,
  .col-md-offset-4,
  .col-md-offset-5,
  .col-md-offset-6,
  .col-md-offset-7,
  .col-md-offset-8,
  .col-md-offset-9,
  .col-md-offset-10,
  .col-md-offset-11,
  .col-md-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .col-md {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-md-1 {
    flex-basis: 8.333%;
    max-width: 8.333%;
  }

  .col-md-2 {
    flex-basis: 16.667%;
    max-width: 16.667%;
  }

  .col-md-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex-basis: 33.333%;
    max-width: 33.333%;
  }

  .col-md-5 {
    flex-basis: 41.667%;
    max-width: 41.667%;
  }

  .col-md-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex-basis: 58.333%;
    max-width: 58.333%;
  }

  .col-md-8 {
    flex-basis: 66.667%;
    max-width: 66.667%;
  }

  .col-md-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex-basis: 83.333%;
    max-width: 83.333%;
  }

  .col-md-11 {
    flex-basis: 91.667%;
    max-width: 91.667%;
  }

  .col-md-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-md-offset-1 {
    margin-left: 8.333%;
  }

  .col-md-offset-2 {
    margin-left: 16.667%;
  }

  .col-md-offset-3 {
    margin-left: 25%;
  }

  .col-md-offset-4 {
    margin-left: 33.333%;
  }

  .col-md-offset-5 {
    margin-left: 41.667%;
  }

  .col-md-offset-6 {
    margin-left: 50%;
  }

  .col-md-offset-7 {
    margin-left: 58.333%;
  }

  .col-md-offset-8 {
    margin-left: 66.667%;
  }

  .col-md-offset-9 {
    margin-left: 75%;
  }

  .col-md-offset-10 {
    margin-left: 83.333%;
  }

  .col-md-offset-11 {
    margin-left: 91.667%;
  }

  .start-md {
    justify-content: flex-start;
    text-align: start;
  }

  .center-md {
    justify-content: center;
    text-align: center;
  }

  .end-md {
    justify-content: flex-end;
    text-align: end;
  }

  .top-md {
    align-items: flex-start;
  }

  .middle-md {
    align-items: center;
  }

  .bottom-md {
    align-items: flex-end;
  }

  .around-md {
    justify-content: space-around;
  }

  .between-md {
    justify-content: space-between;
  }

  .first-md {
    order: -1;
  }

  .last-md {
    order: 1;
  }
}

@media only screen and (min-width: 75em) {
  .container {
    width: 76rem;
  }

  .col-lg,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-offset-1,
  .col-lg-offset-2,
  .col-lg-offset-3,
  .col-lg-offset-4,
  .col-lg-offset-5,
  .col-lg-offset-6,
  .col-lg-offset-7,
  .col-lg-offset-8,
  .col-lg-offset-9,
  .col-lg-offset-10,
  .col-lg-offset-11,
  .col-lg-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .col-lg {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-lg-1 {
    flex-basis: 8.333%;
    max-width: 8.333%;
  }

  .col-lg-2 {
    flex-basis: 16.667%;
    max-width: 16.667%;
  }

  .col-lg-3 {
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex-basis: 33.333%;
    max-width: 33.333%;
  }

  .col-lg-5 {
    flex-basis: 41.667%;
    max-width: 41.667%;
  }

  .col-lg-6 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex-basis: 58.333%;
    max-width: 58.333%;
  }

  .col-lg-8 {
    flex-basis: 66.667%;
    max-width: 66.667%;
  }

  .col-lg-9 {
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex-basis: 83.333%;
    max-width: 83.333%;
  }

  .col-lg-11 {
    flex-basis: 91.667%;
    max-width: 91.667%;
  }

  .col-lg-12 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-lg-offset-1 {
    margin-left: 8.333%;
  }

  .col-lg-offset-2 {
    margin-left: 16.667%;
  }

  .col-lg-offset-3 {
    margin-left: 25%;
  }

  .col-lg-offset-4 {
    margin-left: 33.333%;
  }

  .col-lg-offset-5 {
    margin-left: 41.667%;
  }

  .col-lg-offset-6 {
    margin-left: 50%;
  }

  .col-lg-offset-7 {
    margin-left: 58.333%;
  }

  .col-lg-offset-8 {
    margin-left: 66.667%;
  }

  .col-lg-offset-9 {
    margin-left: 75%;
  }

  .col-lg-offset-10 {
    margin-left: 83.333%;
  }

  .col-lg-offset-11 {
    margin-left: 91.667%;
  }

  .start-lg {
    justify-content: flex-start;
    text-align: start;
  }

  .center-lg {
    justify-content: center;
    text-align: center;
  }

  .end-lg {
    justify-content: flex-end;
    text-align: end;
  }

  .top-lg {
    align-items: flex-start;
  }

  .middle-lg {
    align-items: center;
  }

  .bottom-lg {
    align-items: flex-end;
  }

  .around-lg {
    justify-content: space-around;
  }

  .between-lg {
    justify-content: space-between;
  }

  .first-lg {
    order: -1;
  }

  .last-lg {
    order: 1;
  }
}

.flex-cols {
  display: flex;
}

.flex-one {
  flex: 1 1;
}

.center-align {
  text-align: center;
}

.right-align {
  text-align: right;
}

.title {
  font-size: 25px;
  font-weight: 700;
  padding-top: 20px;
  margin-bottom: 20px;
}

.shadow {
  box-shadow: 0 4px 35px rgba(50, 50, 93, 0.1), 0 8px 15px rgba(0, 0, 0, 0.07);
}

.smallShadow {
  box-shadow: 0 2px 4px rgba(50, 50, 93, 0.1);
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.content {
  box-shadow: 0 2px 4px rgba(50, 50, 93, 0.1);
  background: #fff;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 50px;
  color: #111;
}

.no-padding {
  padding: 0;
}

.divider {
  background: #eee;
  height: 1px;
  width: 100%;
  margin: 40px 0;
}

.loadingGlow {
  opacity: 0.4;
  -webkit-animation: loadingGlow 0.9s infinite;
          animation: loadingGlow 0.9s infinite;
}

@-webkit-keyframes loadingGlow {
  0% {
    opacity: 0.5;
  }

  50% {
    opacity: 0.8;
  }

  100% {
    opacity: 0.5;
  }
}

@keyframes loadingGlow {
  0% {
    opacity: 0.5;
  }

  50% {
    opacity: 0.8;
  }

  100% {
    opacity: 0.5;
  }
}

.paginated-list {
  margin-bottom: 50px;

}

.button {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
}

.button:hover {
  opacity: 0.8;
}

.button:active {
  opacity: 0.7;
}

.button.disabled {
  opacity: 1 !important;
}

.orion_button,
.orion_button:active,
.orion_button:hover {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  box-shadow: none;
  border-radius: 5px;
  background-color: #eee;
  text-transform: none;
}

.orion_button:hover {
  opacity: 0.8;
  box-shadow: none;
  -webkit-transform: none;
          transform: none;
}

.orion_button:active {
  background-color: #eee;
  opacity: 0.7;
  box-shadow: none;
  -webkit-transform: none;
          transform: none;
}

.orion_button.orion_disabled {
  opacity: 1 !important;
}

.orion_danger,
.orion_danger:active,
.orion_danger:hover {
  background-color: #f44336;
}

.orion_primary,
.orion_primary:active,
.orion_primary:hover {
  background-color: #0f2ff1;
}

@-webkit-keyframes loadingPulse {
  from {
    background-color: #cdcdcd;
  }
  50% {
    background-color: #e2e2e2;
  }
  to {
    background-color: #cdcdcd;
  }
}
@keyframes loadingPulse {
  from {
    background-color: #cdcdcd;
  }
  50% {
    background-color: #e2e2e2;
  }
  to {
    background-color: #cdcdcd;
  }
}

.label {
  margin-top: 20px;
  margin-bottom: 10px;
}

.label:first-child {
  margin-top: 0;
}

.autoform-field:first-child > .label {
  margin-top: 0;
}

.autoform-field:not(:first-child) > .label {
  margin-top: 20px;
}

.description {
  margin-top: 0px;
  font-size: 14px;
  color: #999999;
}

.paginated-head {
  padding: 20px;
}

.paginated-error {
  background-color: #fff;
}

.paginated-error-icon svg {
  height: 50px;
  width: 50px;
}

.paginated-head-container {
  margin-bottom: 0;
}

.paginated-head-center {
  min-height: 0px;
}

.paginated-head-right {
  height: 30px;
}

.paginated-pagination {
  margin: 0;
  padding: 20px;
}

.paginated-table {
  padding: 0;
}

.paginated-table table {
  border-top: 1px solid #999;
  width: 100%;
  border-spacing: 0;
}

.paginated-table thead tr {
  background-color: #607D8B !important;
  text-align: left;
  color: #8BC34A;
}

.paginated-table tr {
  border-bottom: 1px solid #CFD8DC;
  border: 1px solid #CFD8DC;
  padding: 0.625em 0.875em;
  background-color: rgb(255, 255, 255);
  color: #8BC34A;
}



.paginated-table tr:nth-child(even) {
  background-color: rgb(246, 250, 242);
}

.paginated-table thead tr th {
  border-bottom: 1px solid #999;
  position: relative;
  line-height: 30px;
  vertical-align: top;
  padding-top: 6px;
  padding-left: 5px;
  padding-bottom: 6px;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
}

.paginated-table tbody tr:hover td {

  background-color: #CFD8DC;
  color: #000;
}

.paginated-table td {
  border-bottom: 1px solid #ccc;
  position: relative;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 5px;
  color: #555;
  vertical-align: center;
  line-height: 30px
}

.paginated-pagination-pages {
  font-size: 14px;
}

.paginated-pagination-limit {
  font-size: 14px;
}

.paginated-pagination-page-input-container {
  top: 0;
}

.paginated-pagination-page-icon,
.paginated-pagination-page-icon-disabled {
  top: 0px;
}

.paginated-pagination-page-icon {
  color: #525f7f;
}

.paginated-pagination-page-icon svg,
.paginated-pagination-page-icon-disabled svg {
  height: 22px;
  width: 22px;
}

.paginated-pagination-page-input {
  background-color: #f6f9fc;
  border-radius: 4px;
  height: 20px;
  width: 32px;
  font-size: 12px;
}

.paginated-text-field-input {
  font-size: 14px;
  background-color: #fff;
  border: 1px solid #e6ebf1;
  border-radius: 4px;
  padding: 4px 7px;
}

.paginated-sort-arrow-up {
  color: #8BC34A;
  margin: -8px;
  margin-bottom: 0px;
}

.paginated-sort-arrow-down {
  color: #8BC34A;
  margin: -8px;
}

body {

  font-family: 'Roboto',
    sans-serif;

}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  color: #000;
  margin: 0;
  font-weight: bold;

}

h1 {
  font-size: 25px;
  padding-top: 20px;
  margin-bottom: 20px;
}

h3 {
  font-size: 20px;
}

a {
  text-decoration: none;
  color: #0f2ff1;
  cursor: pointer;
}

a:hover {
  text-decoration: none;
}

.os-tabs-container {
  border-bottom: 1px solid #eee;
  margin-bottom: 30px;
}

.os-tabs-item a {
  color: #8BC34A;
}


.os-tabs-item {
  font-weight: bold;
}

.os-tabs-container {
  background-color: #455A64;


  color: rgb(117, 165, 63);
  border: 1px solid #455A64;
  border: 0 none;
  border-bottom: none;
  padding: 0.625em 1em;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

}

.os-tabs-item.os-tabs-itemActive a {
  color: #8BC34A !important;
  border-color: none !important;
  text-decoration: none !important;
}

.os-tabs-itemActive a {
  color: deeppink !important;
  border-bottom: none !important;
}

.os-tabs-item a {
  display: flex;
  align-items: center;
  height: 100%;
  color: #fff;
  font-size: 18px;
  border-bottom: none !important;
  box-sizing: border-box;
}

.os-tabs-item a:hover {
  display: flex;
  align-items: center;
  height: 100%;
  color: #fff;
  font-size: 18px;
  border-bottom: none !important;
  box-sizing: border-box;
}

.os-input-text {
  box-shadow: none;
}

.red {
  color: #f43f4f;
}

.orange {
  color: #d45113;
}

.blue  {
  color: #0f2ff1;
}

._2KMpxbIJeQz036jY8tzTq8 {
  border: 1px solid #c2c2c2;
  border-radius: 4px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px;
  background: #fff;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

._3xyKUXp3rmu8ypI7UaA-66 {
  border-color: #0069ff;
}

._3Vhdoe5QaDAmqyj1rdiudn {
  height: 30px;
  outline: none;
  width: 26px;
  margin: 5px;
  padding: 0px 0;
  text-align: center;
  font-size: 20px;
  font-family: inherit;
  font-weight: bold;
  border: none;
  border-bottom: solid 1px #ddd;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  color: transparent;
  text-shadow: 0 0 0 #111;
}

._3Vhdoe5QaDAmqyj1rdiudn:focus {
  border-color: #0069ff;
  outline: none;
}

._3Vhdoe5QaDAmqyj1rdiudn:active {
  border-color: #ddd;
}

._3Vhdoe5QaDAmqyj1rdiudn::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ddd;
}

._3Vhdoe5QaDAmqyj1rdiudn::-moz-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ddd;
}

._3Vhdoe5QaDAmqyj1rdiudn::-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ddd;
}

._3Vhdoe5QaDAmqyj1rdiudn::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ddd;
}

._22_7Ih3JEJyDIHRqUQhdxN {
  text-align: center;
}

._3wlSh5XNZ83-eLZNF37Vi2 {
  height: 100%;
  display: flex;
  flex-direction: row;
}

.rS2OTDjndsLAtvH2tYEjX {
  width: 600px;
  padding: 40px 20px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

._39B6vvgbakuR3i5O4kw8vJ {
  flex: 1 1;
  background-color: #eee;
  background-image: url('https://images.unsplash.com/photo-1491895200222-0fc4a4c35e18?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=7ef79a01f0a12450049b7d20cd10f93c&auto=format&fit=crop&w=2767&q=80');
  background-size: cover;
  background-position: center;
}

footer a {
  text-decoration: none;
  color: #000;
  font-size: 0.8rem;
}

.gorancaguadigital {
  position: fixed;
  bottom: 50px;
  text-align: center;

}

footer {
  position: relative;
  width: 100%;
}

.midle {
  margin-top: 100px;
}

.contenedordata {
  min-height: calc(100vh - 40px);
}

.YGSDVuSx57kTpD7tTyo0L {}

._3cmzC0CJe5iGShtXddJBUP {
  display: flex;
  flex: 1 1;
  align-items: center;
  justify-content: center;
}

._2fGxgCFUGWyoQSg74njMFg {
  text-align: center;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0px !important;
  padding: 5px;
  width: 100%;
  background-color: #f0f0f0;
  color: 000;
  /* margin-top: 40px; */
  text-align: right;
}


@media (max-width: 600px) {

  .react-confirm-alert-body {
    font-family: Arial, Helvetica, sans-serif;
    width: 98% !important;
    margin: 0 auto !important;
    padding: 10px !important;
    text-align: left;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
    color: #666;
  }
}

.os_container_big {
  max-width: 1200px;
  height: 100%;
}

form {
  margin-bottom: 50px;
}

