:local(.container) {
  height: 100%;
  display: flex;
  flex-direction: row;
}

:local(.content) {
  composes: shadow from global;
  width: 600px;
  padding: 40px 20px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

:local(.photo) {
  flex: 1;
  background-color: #eee;
  background-image: url('https://images.unsplash.com/photo-1491895200222-0fc4a4c35e18?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=7ef79a01f0a12450049b7d20cd10f93c&auto=format&fit=crop&w=2767&q=80');
  background-size: cover;
  background-position: center;
}

footer a {
  text-decoration: none;
  color: #000;
  font-size: 0.8rem;
}

.gorancaguadigital {
  position: fixed;
  bottom: 50px;
  text-align: center;

}

footer {
  position: relative;
  width: 100%;
}

.midle {
  margin-top: 100px;
}

.contenedordata {
  min-height: calc(100vh - 40px);
}
