:local(.container) {
  text-align: center;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0px !important;
  padding: 5px;
  width: 100%;
  background-color: #f0f0f0;
  color: 000;
  /* margin-top: 40px; */
  text-align: right;
}


@media (max-width: 600px) {

  .react-confirm-alert-body {
    font-family: Arial, Helvetica, sans-serif;
    width: 98% !important;
    margin: 0 auto !important;
    padding: 10px !important;
    text-align: left;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
    color: #666;
  }
}

.os_container_big {
  max-width: 1200px;
  height: 100%;
}

form {
  margin-bottom: 50px;
}
