.red {
  color: #f43f4f;
}

.orange {
  color: #d45113;
}

.blue  {
  color: #0f2ff1;
}
