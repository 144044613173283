:local(.container) {
  border: 1px solid #c2c2c2;
  border-radius: 4px;
  width: fit-content;
  padding: 5px;
  background: #fff;
  transition: all 0.5s ease;
}

:local(.ready) {
  composes: container;
  border-color: #0069ff;
}

:local(.input) {
  height: 30px;
  outline: none;
  width: 26px;
  margin: 5px;
  padding: 0px 0;
  text-align: center;
  font-size: 20px;
  font-family: inherit;
  font-weight: bold;
  border: none;
  border-bottom: solid 1px #ddd;
  transition: all 0.5s ease;
  color: transparent;
  text-shadow: 0 0 0 #111;
}

:local(.input):focus {
  border-color: #0069ff;
  outline: none;
}

:local(.input):active {
  border-color: #ddd;
}

:local(.input)::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ddd;
}
